import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"
import { hyphenate } from "../utils/urls"

const TagPage = ({ pageContext }) => {
  const { tags } = pageContext

  if (!tags) return null

  return (
    <Layout>
      <SEO title="Tag Archives" path="/blog/tag/" />
      <Container>
        <h1>Tag Archives</h1>
        <ul>
          {tags.map((tag, index) => {
            const url = `/blog/tag/${hyphenate(tag)}/`

            return (
              <li key={index}>
                <Link to={url}>{tag}</Link>
              </li>
            )
          })}
        </ul>
      </Container>
    </Layout>
  )
}

export default TagPage
